import React from 'react';
import { motion } from 'framer-motion';
import Footer from '../components/Footer'; // Footer bileşenini import ediyoruz
import Header from '../components/Header'; // Header bileşenini import ediyoruz
import './styles/HomePage.css'; // Stil dosyası

const HomePage = () => {
  return (
    <>
      <Header /> {/* Header bileşenini en üste ekliyoruz */}
      <motion.div
        className="home-page-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        {/* Hakkımızda Bölümü */}
        <section className="about-us-section">
          <h2>Hakkımızda</h2>
          <p>Delfasoft, yazılım geliştirme ve teknoloji danışmanlığı hizmetlerinde sektörün önde gelen firmalarından biridir. Kuruluşumuzdan bu yana, işletmelere özel çözümler sunarak onların verimliliğini artırmayı hedefledik. Deneyimli ekibimizle, teknolojiyi en iyi şekilde kullanarak müşterilerimizin iş süreçlerini optimize etmelerine yardımcı oluyoruz.</p>
        </section>
        
        <section className="solutions-section">
          <h2>Çözümlerimiz</h2>
          <div className="solution-cards">
            <div className="solution-card">
              <h3>Yazılım Geliştirme</h3>
              <p>Özel yazılım çözümleri ile işletmenizin verimliliğini artırıyoruz.</p>
            </div>
            <div className="solution-card">
              <h3>Yapay Zeka Çözümleri</h3>
              <p>Yapay zeka çözümleri ile işletmenize akıllı teknolojiler kazandırıyoruz.</p>
            </div>
            <div className="solution-card">
              <h3>IoT Çözümleri</h3>
              <p>Nesnelerin interneti (IoT) çözümleri ile cihazlarınızı akıllı sistemlere dönüştürüyoruz.</p>
            </div>
            <div className="solution-card">
              <h3>Mobil Uygulama Geliştirme</h3>
              <p>İşletmeniz için kullanıcı dostu mobil uygulamalar geliştiriyoruz.</p>
            </div>
          </div>
        </section>

        <Footer /> {/* Footer bileşenini en alta ekliyoruz */}
      </motion.div>
    </>
  );
};

export default HomePage;
