import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // React Router'dan Link bileşenini ekliyoruz
import logo from '../assets/images/2.png'; // Logonun yolunu güncelle
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Menü açıp kapama durumu
  };

  return (
    <header className="header-container">
      <div className="logo">
        {/* Link bileşeniyle logoya tıklandığında /anasayfa rotasına yönlendirme yapıyoruz */}
        <Link to="/home">
          <img src={logo} alt="Şirket Logosu" />
        </Link>
      </div>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a href="/contact">İletişim</a>
      </nav>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        {/* Hamburger menü simgesi */}
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </header>
  );
};

export default Header;
