import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/1.png';
import './styles/OpeningPage.css';

const OpeningPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/home');
    }, 4000); 

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="opening-page-container">
      <motion.div
        className="logo-container"
        animate={{
          scale: [1, 2, 1],
        }}
        transition={{
          duration: 4,
          ease: [0.17, 0.67, 0.83, 0.67],
        }}
      >
        <img src={logo} alt="Şirket Logosu" className="company-logo" />
      </motion.div>
    </div>
  );
};

export default OpeningPage;
