import React from 'react';
import Header from '../components/Header'; // Header bileşenini import ediyoruz
import Footer from '../components/Footer'; // Footer bileşenini import ediyoruz
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // İkonları import ediyoruz
import './styles/ContactPage.css';

const ContactPage = () => {
  return (
    <>
      <Header /> {/* Header bileşenini ekliyoruz */}
      <div className="contact-container">
        <div className="contact-info">
          <div className="contact-box">
            <FaPhoneAlt /> {/* Telefon ikonu */}
            <p>+90 505 799 5336</p>
            <p>+90 552 401 4898</p>
          </div>
          <div className="contact-box">
            <FaEnvelope /> {/* E-posta ikonu */}
            <p>info@delfasoft.net</p>
          </div>
          <div className="contact-box">
            <FaMapMarkerAlt /> {/* Adres ikonu */}
            <p>Yeşilce Mah. , Dalgıç Sk. No:3 / 5 , Kat:1</p>
            <p>34418 Kağıthane, İstanbul</p>
          </div>
        </div>
        <div className="map-container">
          <iframe
            title="Delfasoft Yazılım ve Teknoloji Danışmanlığı Hizmetleri"
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d96236.68384639705!2d28.892152300997473!3d41.082185508993504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14cab67c31083271%3A0x2938e8776ff69bbd!2zWWXFn2lsY2UsIERhbGfEscOnIFNrLiBObzozLCAzNDQxOCBLw6LEn8SxdGhhbmUvxLBzdGFuYnVs!3m2!1d41.0933181!2d29.0027816!5e0!3m2!1str!2str!4v1727010374809!5m2!1str!2str"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <Footer /> {/* Footer bileşenini ekliyoruz */}
    </>
  );
};

export default ContactPage;
