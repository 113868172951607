import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OpeningPage from './pages/OpeningPage';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage'; // İletişim sayfasını ekledik
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; // Gizlilik politikası sayfasını ekledik
import { AnimatePresence } from 'framer-motion';

function App() {
  return (
    <Router>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<OpeningPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} /> {/* İletişim rotası */}
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> {/* Gizlilik politikası rotası */}
        </Routes>
      </AnimatePresence>
    </Router>
  );
}

export default App;
