import React from 'react';
import './Footer.css'; // CSS dosyasını import ediyoruz

const Footer = () => {
  return (
    <footer className="footer-container">
      <p>© {new Date().getFullYear()} Delfasoft Yazılım ve Teknoloji Danışmanlık Hizmetleri Tüm hakları saklıdır.</p>
      <a href="/privacy-policy" className="privacy-policy-link">Gizlilik Politikası</a> {/* Gizlilik politikası bağlantısı */}
    </footer>
  );
};

export default Footer;
