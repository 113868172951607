import React from 'react';
import Header from '../components/Header'; // Header bileşenini ekliyoruz
import Footer from '../components/Footer'; // Footer bileşenini ekliyoruz
import './styles/PrivacyPolicyPage.css'; // CSS dosyasını import ediyoruz

const PrivacyPolicyPage = () => {
  return (
    <>
      <Header /> {/* Header'ı sayfanın üstüne ekliyoruz */}
      <div className="privacy-policy-container">
        <h1>Gizlilik Politikası</h1>
        
        <section>
          <h2>1. Giriş</h2>
          <p>
            Delfasoft Yazılım ve Teknoloji Danışmanlığı Hizmetleri (“biz”, “şirket”, “Delfasoft Yazılım ve Teknoloji Danışmanlığı Hizmetleri”) olarak, kullanıcılarımızın gizliliğine saygı gösteriyor ve onların kişisel verilerini koruma taahhüdünde bulunuyoruz. Bu gizlilik politikası, geliştirdiğimiz tüm yazılımlar ve uygulamalar (“Uygulama”) aracılığıyla toplanan kişisel ve hassas verilerin nasıl toplandığını, kullanıldığını, paylaşıldığını ve korunduğunu açıklamaktadır.
          </p>
        </section>

        <section>
          <h2>2. Toplanan Bilgiler</h2>
          <p>
            Geliştirdiğimiz yazılımlar ve uygulamalar, aşağıdaki türde kişisel ve hassas kullanıcı verilerini toplayabilir:
          </p>
          <ul>
            <li>Kişisel Bilgiler: İsim, e-posta adresi, telefon numarası, kullanıcı adı gibi kimliği belirleyici bilgiler.</li>
            <li>Cihaz Bilgileri: Cihaz türü, işletim sistemi, benzersiz cihaz tanımlayıcıları, IP adresi gibi bilgiler.</li>
            <li>Kullanım Verileri: Uygulamanın nasıl kullanıldığına dair bilgiler, erişim süreleri, görüntülenen sayfalar vb.</li>
            <li>Konum Verileri: Kullanıcının bulunduğu yerin belirlenmesi için kullanılan coğrafi konum bilgileri.</li>
          </ul>
        </section>

        <section>
          <h2>3. Verilerin Kullanımı</h2>
          <p>
            Toplanan veriler aşağıdaki amaçlarla kullanılabilir:
          </p>
          <ul>
            <li>Uygulama işlevselliğini sağlamak ve iyileştirmek.</li>
            <li>Kullanıcı destek hizmetleri sunmak.</li>
            <li>Uygulama performansını ve kullanıcı deneyimini analiz etmek.</li>
            <li>Yasal zorunluluklara uymak ve kullanıcı güvenliğini sağlamak.</li>
            <li>Pazarlama ve tanıtım amaçları için kullanıcılarla iletişime geçmek.</li>
          </ul>
        </section>

        <section>
          <h2>4. Verilerin Paylaşımı</h2>
          <p>
            Delfasoft Yazılım ve Teknoloji Danışmanlığı Hizmetleri, kullanıcıların kişisel verilerini aşağıdaki durumlar haricinde üçüncü taraflarla paylaşmaz:
          </p>
          <ul>
            <li>Hizmet Sağlayıcıları: Verilerin işlenmesi, saklanması veya analiz edilmesi için üçüncü taraf hizmet sağlayıcılarla iş birliği yapabiliriz.</li>
            <li>Yasal Gereklilikler: Yasal bir zorunluluk, mahkeme kararı veya devlet talebi gereği kullanıcı verilerini yetkili mercilerle paylaşabiliriz.</li>
            <li>Birleşme veya Satış: Şirketimizin başka bir şirketle birleşmesi veya satılması durumunda kullanıcı verileri, yeni oluşumun bir parçası olabilir.</li>
          </ul>
        </section>

        <section>
          <h2>5. Veri Güvenliği</h2>
          <p>
            Kullanıcı verilerinin güvenliğini sağlamak için modern kriptografi yöntemleri (örneğin, HTTPS üzerinden veri iletimi) kullanıyoruz. Verilerin yetkisiz erişime, değiştirilmesine veya ifşa edilmesine karşı korunması için gerekli teknik ve idari önlemler alınmaktadır.
          </p>
        </section>

        <section>
          <h2>6. Veri Saklama ve Silme</h2>
          <p>
            Kullanıcı verileri, işbu gizlilik politikasında belirtilen amaçlar için gerekli olduğu sürece saklanır. Kullanıcılar, uygulama hesabını silme talebinde bulunabilirler ve bu talep doğrultusunda verileri de silinir. Ancak, yasal gereklilikler nedeniyle belirli verileri daha uzun süre saklama zorunluluğumuz olabilir.
          </p>
        </section>

        <section>
          <h2>7. Kullanıcı Hakları</h2>
          <p>
            Kullanıcılar, kişisel verilerine erişim talep etme, bunları düzeltme, silme veya işlenmesine itiraz etme hakkına sahiptir. Bu haklarınızı kullanmak için lütfen aşağıda belirtilen iletişim bilgileri üzerinden bizimle iletişime geçin.
          </p>
        </section>

        <section>
          <h2>8. İletişim Bilgileri</h2>
          <p>
            Gizlilik politikamızla ilgili herhangi bir sorunuz veya talebiniz varsa, lütfen aşağıdaki iletişim bilgilerini kullanarak bizimle iletişime geçin:
          </p>
          <address>
            Delfasoft Yazılım ve Teknoloji Danışmanlığı Hizmetleri<br />
            Adres: Yeşilce Mah. , Dalgıç Sk. No:3 / 5 , Kat:1 , 34418 Kağıthane - İstanbul<br />
            E-posta: info@delfasoft.net
          </address>
        </section>

        <section>
          <h2>9. Politika Değişiklikleri</h2>
          <p>
            Bu gizlilik politikası, zaman zaman güncellenebilir. Politika değişiklikleri uygulamamızda veya web sitemizde yayınlandıktan sonra geçerli olacaktır. Kullanıcılarımızın düzenli olarak bu politikayı gözden geçirmesini tavsiye ederiz.
          </p>
        </section>

        <section>
          <h2>10. Yürürlük Tarihi</h2>
          <p>
            Bu gizlilik politikası, 22.09.2024 itibariyle geçerli olacaktır.
          </p>
        </section>
      </div>
      <Footer /> {/* Footer'ı sayfanın altına ekliyoruz */}
    </>
  );
};

export default PrivacyPolicyPage;